import "./styles.css";
import clipboardIcon from "../assets/clipboard-icon.svg";
import checkMark from "../assets/check-mark.svg";
import { createNotification } from "../Notifications";

import { useState } from "react";
const Copiable = ({ link }) => {
  const [clicked, setClicked] = useState(0);
  return (
    <div className="linkBox">
      <div className="linkText">{link}</div>
      {clicked ? (
        <div className="copied">
          <img src={checkMark} className="copyIcon" />
        </div>
      ) : (
        <div
          className="copyButton"
          onClick={() => {
            navigator.clipboard.writeText(link);
            setClicked(1);
            createNotification("Copied to Clipboard!", false);
          }}
        >
          <img src={clipboardIcon} className="copyIcon"></img>
        </div>
      )}
    </div>
  );
};
export default Copiable;
