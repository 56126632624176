import { store } from "../store";
import { useContext } from "react";
import { Navigate } from "react-router";

const RequireAuth = ({ children }) => {
  const { state } = useContext(store);

  return state.auth ? children : <Navigate to="/login" replace />;
};

export default RequireAuth;
