import "./App.css";
import { Outlet } from "react-router-dom";
import afyaLogo from "./assets/afya-logo.png";
import NavBar from "./NavBar";
import NewUserModal from "./NewUserModal";
function App() {
  return (
    <>
      <NewUserModal />
      <div class="master">
        <NavBar />
        <div class="contentContainer">
          <Outlet />
        </div>
        <footer>
          <div class="footer">
            <img src={afyaLogo} class="afyaLogo" />
            <p>Powered By Afya</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
