import FormInput from "../FormInput";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { signIn } from "../auth";
import { store } from "../store.js";
import { useNavigate } from "react-router";
import { useContext } from "react";
import background from "../assets/medical_icon_image.jpeg";
import "./styles.css";
import { readAuthError } from "../utils";
import { createNotification } from "../Notifications";

const Login = () => {
  const { dispatch, state } = useContext(store);
  const navigate = useNavigate();
  return (
    <div
      className="pageContainer"
      style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}
    >
      <div className="loginBox">
        <div className="welcomeText">Welcome Back</div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().email("Invalid email address"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const res = await signIn(values.email, values.password);
              dispatch({ type: "SIGNED_IN", data: res.auth });
              navigate(state.targetPage);
            } catch (error) {
              const decodedMsg = readAuthError(error);
              const errorMsg = decodedMsg
                ? decodedMsg
                : "User Could Not Be Signed In";
              createNotification(errorMsg);
            }
          }}
        >
          <Form>
            <FormInput label="Email" name="email" type="text"></FormInput>
            <FormInput
              label="Password"
              name="password"
              type="password"
            ></FormInput>
            <div className="btnRow">
              <button className="loginButton" type="submit">
                Login
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
