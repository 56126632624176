import { useState, useEffect, useCallback } from "react";
import { getUserList, getUserInfo, streamAppointments } from "../db";
import { Fragment, useContext } from "react";
import { store } from "../store";
import Appointment from "./Appointment.js";
import { deleteAppointment } from "../db";
import ConfirmationModal from "../ConfirmationModal";
import "./styles.css";
import { addDays } from "./utils.js";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_overrides.css";
import Select from "react-select";
import departments from "../departments";
import Spinner from "./Spinner.js";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import AppointmentList from "./AppointmentList.js";
import { createNotification } from "../Notifications";

const selectStyles = {
  container: (provided, state) => ({ ...provided, width: 250, height: 35 }),
  input: (provided, state) => ({ ...provided, width: 250, height: 35 }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "#5b5b5b",
    backgroundColor: state.isFocused && "#638a2f",
    color: state.isFocused && "white",
    // padding: 20,
  }),
};

const Dashboard = () => {
  const defaultStart = moment();

  const defaultEnd = moment().add(1, "days");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [appointments, setAppointments] = useState([]);

  const [startInput, setStartDate] = useState(defaultStart);
  const [endInput, setEndDate] = useState(defaultEnd);
  const [finalStart, setFinalStart] = useState(defaultStart);
  const [finalEnd, setFinalEnd] = useState(defaultEnd);
  const [dateFocus, setDateFocus] = useState();
  const [department, setDepartment] = useState("All");
  const [staffOptions, setStaffOptions] = useState([]);
  const [provider, setProvider] = useState("All");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const { state } = useContext(store);
  const { uid, email, first_name, last_name } = state.auth.currentUser;
  const [userInfo, setUserInfo] = useState({});
  const [dateModalStatus, setDatePickerStatus] = useState();
  const [departmentIsVisible, setDepartmentVisibility] = useState(true);
  const [providerIsVisible, setProviderVisibility] = useState(true);
  const [idForDeletion, setIdForDeletion] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    const addUserInfo = async () => {
      const userData = await getUserInfo(uid);
      setUserInfo(userData);
      if (userData.role === "provider") {
        setProvider(userData.email);
        setProviderVisibility(false);
      }
      if ("staff" === userData.role) {
        setDepartment(userData.department);
        setDepartmentVisibility(false);
      }
    };
    addUserInfo();
  }, [uid]);
  useEffect(() => {
    const getProviders = async () => {
      const providers = await getUserList(department);
      providers.unshift({ value: "All", label: "All" });
      setStaffOptions(providers);
      return providers;
    };
    getProviders();
  }, [department]);

  useEffect(() => {
    const unsubscribe = streamAppointments(
      startInput,
      endInput,
      provider,
      department,
      (appSnapshot) => {
        const updatedApps = [];
        appSnapshot.docs.map((doc) => {
          let data = doc.data();
          if (data.isHidden) return;
          if (data.patientFirst && data.meetingId) updatedApps.push(data);
        });
        setAppointments(updatedApps);
        setLoadingStatus(false);
      },
      (error) => setError(error),
      (loadingStatus) => setLoadingStatus(loadingStatus)
    );
    return unsubscribe;
  }, [provider, finalStart, finalEnd, department, setAppointments]);

  const deletionHandler = useCallback((id) => {
    setIdForDeletion(id);
    setModalOpened(true);
  }, []);

  return (
    <div className="cardContainer">
      <ConfirmationModal
        opened={modalOpened}
        setOpened={setModalOpened}
        title="Are you sure you want to delete this appointment?"
        description="Are you sure you want to delete this appointment"
        action={deleteAppointment}
        actionArgs={[idForDeletion]}
      />
      <div className="filters">
        <div>
          <div className="filterLabel">Appointment Date Range</div>
          <DateRangePicker
            startDate={startInput}
            startDateId="start_date_id"
            endDate={endInput}
            endDateId="end_date_id"
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            focusedInput={dateFocus}
            onFocusChange={(focusedInput) => {
              setDateFocus(focusedInput);
            }}
            keepOpenOnDateSelect={true}
            isOutsideRange={() => false}
            onClose={({ startDate, endDate }) => {
              if (startDate !== null) setFinalStart(startDate);
              else {
                createNotification(
                  "Please write a leading zero when typing months or days (e.g. 04/02/2022) or use the date picker."
                );
                setStartDate(defaultStart);
                setFinalStart(defaultStart);
              }
              if (endDate !== null) setFinalEnd(endDate);
              else {
                createNotification(
                  "Please write a leading zero when typing months or days (e.g. 04/02/2022) or use the date picker."
                );
                setEndDate(defaultEnd);
                setFinalEnd(defaultEnd);
              }
            }}
          />
        </div>

        {["super_admin", "admin"].includes(userInfo.role) && (
          <div>
            <div className="filterLabel">Department</div>
            <Select
              options={departments}
              placeholder={"All"}
              onChange={(selected) => {
                setDepartment(selected.value);
              }}
              styles={selectStyles}
            />
          </div>
        )}
        {["super_admin", "admin", "staff"].includes(userInfo.role) && (
          <div>
            <div className="filterLabel">Clinician Name</div>
            <Select
              options={staffOptions}
              placeholder={"All"}
              onChange={(selected) => {
                setProvider(selected.value);
              }}
              styles={selectStyles}
            />
          </div>
        )}
      </div>
      <div
        style={{ marginBottom: "15px", fontWeight: "bold", fontSize: "25px" }}
      >
        Scheduled Appointments
      </div>
      <AppointmentList
        loadingStatus={loadingStatus}
        appointments={appointments}
        deletionHandler={deletionHandler}
      />
    </div>
  );
};

export default Dashboard;
