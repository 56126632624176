import { showNotification } from "@mantine/notifications";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

export const createNotification = (
  message = "A problem occured",
  isError = true
) => {
  showNotification({
    color: isError ? "red" : "green",
    icon: isError ? <AiFillCloseCircle /> : <AiFillCheckCircle />,
    message,
  });
};
