import { useAuth } from "../hooks";

const UserButton = () => {
  const { isSignedIn, signOut, signIn } = useAuth();
  return (
    <button className="signInBtn" onClick={isSignedIn ? signOut : signIn}>
      {isSignedIn ? "Sign Out" : "Sign In"}
    </button>
  );
};

export default UserButton;
