import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Formik, Form, useField } from "formik";
import LongInput from "../LongInput/index.js";
import { useWindowScroll } from "@mantine/hooks";
import "./styles.css";
import { sendSMS, sendEmail, scheduleSMS } from "../API/index.js";
import * as Yup from "yup";
import {
  convertDate,
  convertTime,
  createURL,
  createTimeStamp,
} from "../utils.js";
import Copiable from "../Copiable/index.js";
import { base_url, org } from "../whitelabel.js";
import { createAppointment } from "../db.js";
import { createNotification } from "../Notifications";

const MessageConfirmation = () => {
  let navigate = useNavigate();
  let { state } = useLocation();
  const [scroll, scrollTo] = useWindowScroll();

  useEffect(() => {
    if (state === null) {
      navigate("/Appointment");
    }
  });

  useEffect(() => {
    scrollTo({ y: 0 });
  }, []);

  if (state === null) return null;
  if (!state.patientMobile) state.patientMobile = "";
  if (!state.clinicianMobile) state.clinicianMobile = "";

  const patientFullname = state.patientFirst + " " + state.patientLast;
  const clinicianFullName = state.clinicianFirst + " " + state.clinicianLast;
  let full_url = `${base_url}${state.meetingId}`;

  let patientTemplate = "";
  let clinicianTemplate = "";
  let body = "";

  const createEmailBody = (body, isProvider) => {
    if (!state) return;
    const name = isProvider
      ? state.clinicianFirst + " " + state.clinicianLast + "."
      : state.patientFirst + " " + state.patientLast + ".";
    return body + " with " + name;
  };

  if (state !== null) {
    body =
      "You have a telehealth visit on " +
      convertDate(state.appDate) +
      " at " +
      convertTime(state.appTime);
    if (state.patientMobile) {
      patientTemplate =
        body +
        " with " +
        state.clinicianFirst[0].toUpperCase() +
        ". " +
        state.clinicianLast +
        ". Join link:" +
        full_url;
    }
    if (state.clinicianMobile) {
      clinicianTemplate =
        body +
        " with " +
        state.patientFirst[0].toUpperCase() +
        ". " +
        state.patientLast +
        ". Join link:" +
        full_url;
    }
  }

  return (
    <Formik
      initialValues={{
        patientMsg: patientTemplate,
        clinicianMsg: clinicianTemplate,
      }}
      validationSchema={Yup.object({
        patientMsg: Yup.string().max(
          152,
          "Message must be 152 characters or less."
        ),
        clinicianMsg: Yup.string().max(
          152,
          "Message must be 152 characters or less."
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);

        try {
          if (state.patientMobile) {
            sendSMS(state.patientMobile, values.patientMsg);
            scheduleSMS(
              state.patientMobile,
              values.patientMsg,
              createTimeStamp(state.appDate, state.appTime),
              state.meetingId //passing to skip SMS trigger if appointent is deleted
            );
          }
          if (state.patientEmail) {
            sendEmail(
              state.patientEmail,
              state.patientFirst + " " + state.patientLast,
              createEmailBody(body, true),
              full_url,
              org
            );
          }
          if (state.doEmailClinician) {
            sendEmail(
              state.clinicianEmail,
              state.clinicianFirst + " " + state.clinicianLast,
              createEmailBody(body, false),
              full_url,
              org
            );
          }
          if (values.clinicianMsg && state.doSMSClinician)
            sendSMS(state.clinicianMobile, values.clinicianMsg);
        } catch (error) {
          createNotification("Appointment could not be created.");
          return;
        }

        createAppointment(
          {
            ...state,
            patientMobile: state.patientMobile ? state.patientMobile : "",
            clinicianMobile: state.clinicianMobile ? state.clinicianMobile : "",
            patientEmail: state.patientEmail ? state.patientEmail : "",
            clinicianEmail: state.clinicianEmail ? state.clinicianEmail : "",
            datetime: createTimeStamp(state.appDate, state.appTime),
            createdAt: new Date(),
            associator: state.clinicianEmail
              ? state.clinicianEmail
              : state.clinicianMobile,
            patientStatus: {
              connectivity: "DISCONNECTED",
              mic: 3,
              camera: 3,
              participation: "ABSENT",
            },
          },
          state.meetingId
        );
        navigate("/completion", { state: state });
      }}
    >
      <div className="msgContainer">
        <div className="infoCol">
          <div className="infoRow">
            <div className="infoSeg">
              <div className="infoLabel">PATIENT NAME</div>
              <div className="patientName">{patientFullname}</div>
            </div>
          </div>
          <div className="infoRow">
            <div className="infoSeg">
              <div className="infoLabel">CLINICIAN NAME</div>
              <div className="patientName">{clinicianFullName}</div>
            </div>
          </div>
          <div className="infoRow">
            <div className="infoSeg">
              <div className="infoLabel">ROOM LINK</div>
              <Copiable link={full_url} />
            </div>
          </div>
        </div>
        <div className="formCol">
          <Form>
            <LongInput
              label="Patient Text Message"
              name="patientMsg"
              type="textarea"
              className="bigInput"
            />
            <div
              class="sndFormRow"
              style={{ display: state.doSMSClinician ? "flex" : "none" }}
            >
              <LongInput
                label="Clinician Text Message"
                name="clinicianMsg"
                type="textarea"
                className="bigInput"
              />
            </div>
            <div className="sendBtnRow">
              <button className="sendBtn" type="submit">
                Send Appointment
              </button>
            </div>
          </Form>
        </div>
      </div>
    </Formik>
  );
};

export default MessageConfirmation;
