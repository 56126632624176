import { Modal, Center, Button } from "@mantine/core";
import { Formik, Form } from "formik";
import FormInput from "../FormInput";
import FormSelect from "../FormSelect";
import { useContext } from "react";
import { store } from "../store";
import roleOptions from "./roleOptions";
import DepartmentSelect from "./DepartmentSelect";
import * as Yup from "yup";
import { createUser, resetPassword } from "../auth";
import { useState } from "react";
import "./styles.css";
import { createNotification } from "../Notifications";
import { readAuthError } from "../utils";

const NewUserModal = () => {
  const { state, dispatch } = useContext(store);
  const [department, setDepartment] = useState();
  const [role, setRole] = useState();
  const [departmentIsValid, setDepartmentIsValid] = useState(null);
  const [roleIsValid, setRoleIsValid] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      opened={state.userModalOpen}
      onClose={() => {
        setRole("");
        setDepartment("");
        setDepartmentIsValid(null);
        setRoleIsValid(null);
        dispatch({ type: "CLOSE_USER_MODAL", data: null });
      }}
      size={500}
      padding={40}
      withCloseButton={false}
      centered
    >
      <Formik
        initialValues={{
          first: "",
          last: "",
          email: "",
          department: "",
          role: "",
        }}
        validationSchema={Yup.object({
          first: Yup.string()
            .max(40, "Must be 40 characters or less")
            .required("User first name is required"),
          last: Yup.string()
            .max(40, "Must be 40 characters or less")
            .required("Patient last name is required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
          department: Yup.string(),
          role: Yup.string(),
        })}
        onSubmit={async (values) => {
          setIsLoading(true);
          try {
            const res = await createUser(
              values.email,
              values.first,
              values.last,
              department,
              role
            );
            if (!res.error) await resetPassword(values.email, res.auth);
            createNotification("User Created", false);
            dispatch({ type: "CLOSE_USER_MODAL", data: null });
          } catch (error) {
            console.error("error while creating user", error);
            const decodedMsg = readAuthError(error);
            const errorMsg = decodedMsg
              ? decodedMsg
              : "User Could Not be Created";
            createNotification(errorMsg);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <Form
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
            height: "520px",
          }}
        >
          <FormInput
            label="*First Name"
            name="first"
            type="text"
            style={{ height: "50px" }}
          />
          <FormInput
            label="*Last Name"
            name="last"
            type="text"
            style={{ height: "50px" }}
          />
          <FormInput
            label="*Email"
            name="email"
            type="email"
            style={{ height: "50px" }}
          />
          <DepartmentSelect
            value={department}
            changeHandler={setDepartment}
            isValid={departmentIsValid}
            setIsValid={setDepartmentIsValid}
          />
          <FormSelect
            label="*Role"
            options={roleOptions}
            name="role"
            value={role}
            changeHandler={setRole}
            isValid={roleIsValid}
            setIsValid={setRoleIsValid}
          />
          <Button
            loading={isLoading}
            type="submit"
            className="createUserBtn"
            sx={{
              "&:hover": {
                backgroundColor: "#7da14c",
              },
            }}
          >
            Create User
          </Button>
        </Form>
      </Formik>
    </Modal>
  );
};

export default NewUserModal;
