import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import "./styles.css";

export const PageHeader = ({
  title,
  route = "/",
  isInternalLink = true,
  action = null,
}) => {
  const { pathname } = useLocation();
  const color = pathname === route ? "#4985a4" : "#797979";
  const header = !isInternalLink ? (
    <div className="pageTitle" onClick={action}>
      {title}
    </div>
  ) : (
    <Link to={route} className="pageTitle" style={{ color: color }}>
      {title}
    </Link>
  );
  return header;
};
