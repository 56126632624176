import axios from "axios";
import { createNotification } from "../Notifications";

export const sendEmail = (email, name, msg_body, link, org) => {
  const data = JSON.stringify({
    address: email,
    name: name,
    msgBody: msg_body,
    link: link,
    org: org,
  });
  const config = {
    method: "post",
    url: "https://us-west2-website-hosting-309708.cloudfunctions.net/sendEmail",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};

export const sendSMS = (mobile_number, msg_body) => {
  var data = JSON.stringify({
    mobile_number: mobile_number,
    msg: msg_body,
    sandbox: 1
  });

  var config = {
    method: "post",
    url: "https://us-west2-website-hosting-309708.cloudfunctions.net/sendInvite-sandbox",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {})
    .catch(function (error) {});
};

export const getAppointmentId = async (appointmentData) => {
  const config = {
    method: "post",
    url: "https://us-west2-website-hosting-309708.cloudfunctions.net/createURL-sandbox",
    data: appointmentData,
    headers: { "Content-Type": "application/json" },
  };
  try {
    const res = await axios(config);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const scheduleSMS = async (mobile_number, msg_body, visitTime, meetingId) => {
  visitTime.setDate(visitTime.getDate() - 1);
  if (visitTime < new Date()) {
    return;
  }
  const config = {
    method: "POST",
    url: "https://us-west2-website-hosting-309708.cloudfunctions.net/scheduleSMS-sandbox",
    data: {
      mobile_number,
      msg_body,
      sendAt: visitTime.getTime(),
      meetingId, //passing to skip SMS trigger if appointent is deleted
      sandbox: 1
    },
  };
  try {
    const res = await axios(config);
  } catch (e) {
    console.error("Error in scheduleSMS medthod", e);
    createNotification(
      "Something went wrong when trying to schedule a reminder. Reminders can only be scheduled 29 days out"
    );
  }
};
