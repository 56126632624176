import { useParams } from "react-router";
import "./styles.css";
import { useEffect } from "react";
import { base_url, org } from "../whitelabel.js";
import { addDash } from "../utils.js";

function getLocalStream() {
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then((stream) => {
      window.localStream = stream;
      window.localAudio.srcObject = stream;
      window.localAudio.autoplay = true;
    })
    .catch((err) => {});
}

const Room = ({}) => {
  useEffect(() => {
    getLocalStream();
  });
  let urlSearchParams = useParams();
  let roomId = urlSearchParams.roomId;

  const url = "https://rsbcihi.meetmonk.com/#/" + roomId;

  return (
    <iframe
      className="room"
      src={url}
      allow="camera; microphone; display-capture"
      allowFullScreen={true}
    />
  );
};

export default Room;
