import "./styles.css";
import vcImage from "../assets/virtual-care-graphic.png";
import { Link } from "react-router-dom";
import { updateAppointments } from "../db";
import { store } from "../store.js";
import { useContext } from "react";

const Splash = (props) => {
  const { dispatch } = useContext(store);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        paddingLeft: "5%",
        height: "100%",
        width: "95%",
      }}
    >
      <div class="fstCol">
        <div class="headline">
          Coordinate Telehealth For A Better Patient Experience
        </div>
        <div class="description">
          Create, monitor and join appointments all on one platform.
        </div>
        <div class="btnRow">
          <Link to={"/appointment"}>
            <button
              onClick={() => {
                dispatch({ type: "CAPTURE_TARGET", data: "/appointment" });
              }}
              class="btnDefault"
            >
              Create Appointment
            </button>
          </Link>
          <Link to={"/notify"}>
            <button
              onClick={() => {
                dispatch({ type: "CAPTURE_TARGET", data: "/notify" });
              }}
              class="btnOther"
            >
              Contact Patient
            </button>
          </Link>
        </div>
      </div>

      <div class="sndCol">
        <img src={vcImage} alt="virtual care graphic" class="img" />
      </div>
    </div>
  );
};

export default Splash;
