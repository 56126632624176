import "./styles.css";
import { useState } from "react";
import {
  MdMic,
  MdMicOff,
  MdVideocamOff,
  MdVideocam,
  MdSignalWifi4Bar,
  MdSignalWifiOff,
  MdCheckCircleOutline,
  MdQueryBuilder,
} from "react-icons/md";
import { IconContext } from "react-icons";
import { capitalizeFirst } from "../utils";

export const TooltipContent = ({ status }) => {
  let { camera, mic, connectivity, participation } = status;
  if (participation === "CONT") participation = "JOINED";
  const joinColor = participation === "JOINED" ? "#2E64AE" : "#F57E20";
  const patientLine = (
    <div className="statusLine">
      <IconContext.Provider value={{ color: joinColor, size: "25px" }}>
        {participation === "JOINED" ? (
          <MdCheckCircleOutline />
        ) : (
          <MdQueryBuilder />
        )}
      </IconContext.Provider>
      <div className="statusLabel" style={{ color: joinColor }}>
        {"Patient " + capitalizeFirst(participation)}
      </div>
    </div>
  );
  let micLine, cameraLine, connectivityLine;
  if (mic === 0) {
    micLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#C70808", size: "25px" }}>
          <MdMicOff />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#C70808" }}>
          Mic Not Available
        </div>
      </div>
    );
  } else if (mic === 1) {
    micLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#C70808", size: "25px" }}>
          <MdMicOff />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#C70808" }}>
          Mic Under Use
        </div>
      </div>
    );
  } else if (mic === 2) {
    micLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#2E64AE", size: "25px" }}>
          <MdMic />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#2E64AE" }}>
          Mic Connected
        </div>
      </div>
    );
  } else {
    micLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#F57E20", size: "25px" }}>
          <MdMicOff />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#F57E20" }}>
          Mic Disconnected
        </div>
      </div>
    );
  }
  if (camera === 0) {
    cameraLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#C70808", size: "25px" }}>
          <MdVideocamOff />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#C70808" }}>
          Camera Not Available
        </div>
      </div>
    );
  } else if (camera === 1) {
    cameraLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#C70808", size: "25px" }}>
          <MdVideocamOff />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#C70808" }}>
          Camera Under Use
        </div>
      </div>
    );
  } else if (camera === 2) {
    cameraLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#2E64AE", size: "25px" }}>
          <MdVideocam />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#2E64AE" }}>
          Camera Connected
        </div>
      </div>
    );
  } else {
    cameraLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#F57E20", size: "25px" }}>
          <MdVideocamOff />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#F57E20" }}>
          Camera Disconnected
        </div>
      </div>
    );
  }
  if (connectivity === "POOR") {
    connectivityLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#C70808", size: "25px" }}>
          <MdSignalWifiOff />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#C70808" }}>
          Poor Signal
        </div>
      </div>
    );
  } else if (connectivity === "AVG") {
    connectivityLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#F57E20", size: "25px" }}>
          <MdSignalWifi4Bar />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#F57E20" }}>
          Average Signal
        </div>
      </div>
    );
  } else if (connectivity === "GOOD") {
    connectivityLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#2E64AE", size: "25px" }}>
          <MdSignalWifi4Bar />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#2E64AE" }}>
          Average Signal
        </div>
      </div>
    );
  } else {
    connectivityLine = (
      <div className="statusLine">
        <IconContext.Provider value={{ color: "#F57E20", size: "25px" }}>
          <MdSignalWifi4Bar />
        </IconContext.Provider>
        <div className="statusLabel" style={{ color: "#F57E20" }}>
          Internet Disconnected
        </div>
      </div>
    );
  }
  return (
    <div>
      {patientLine}
      {micLine}
      {cameraLine}
      {connectivityLine}
    </div>
  );
};

export const Tooltip = ({ children, content, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="tooltip-container">
      <div className={show ? "tooltip-box visible" : "tooltip-box"}>
        {content}
        <span className="tooltip-arrow" />
      </div>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};
