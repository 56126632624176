import { Select, Input, createStyles } from "@mantine/core";
import { useState, useEffect } from "react";
import FormSelect from "../FormSelect";
import { getUserList } from "../db.js";
import "../App.css";

const useStyles = createStyles((theme) => ({
  selector: {
    width: "400px",
    height: "100px",
  },
}));

const ClinicianSelect = ({ setName, setEmail, isValid, setIsValid }) => {
  const [clinicians, setClinicians] = useState([]);
  const [selected, setSelected] = useState();

  const handleChange = (input) => {
    setSelected(input);
    const { name, email } = JSON.parse(input);
    setName(name);
    setEmail(email);
  };

  useEffect(() => {
    const getClinicans = async () => {
      const allClinicians = await getUserList("All", true);
      setClinicians(allClinicians);
    };
    getClinicans();
  }, []);

  return (
    <FormSelect
      label="*Clinician Name"
      options={clinicians}
      value={selected}
      changeHandler={handleChange}
      isValid={isValid}
      setIsValid={setIsValid}
      overrideStyles={{ height: "40px" }}
    />
    // <div>
    //   <div style={{ color: "#2e64ae", fontWeight: "bold", fontSize: "15px" }}>
    //     *Clinician Name
    //   </div>
    //   <Select
    //     styles={{
    //       input: {
    //         width: "300px",
    //         height: "40px",
    //         border: "2px solid #2e64ae",
    //         borderRadius: "4px",
    //         fontSize: "15px",
    //         paddingTop: "10px",
    //         paddingLeft: "10px",
    //       },
    //       label: { color: "#2e64ae", fontWeight: "bold", fontSize: "15px" },
    //     }}
    //     variant="headless"
    //     value={selected}
    //     onChange={handleChange}
    //     data={clinicians}
    //     searchable
    //   />
    // </div>
  );
};

export default ClinicianSelect;
