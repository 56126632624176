import { useState } from "react";
import "./styles.css";
import FormInput from "../FormInput/index.js";
import { Formik, Form, useField } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input/input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Error from "../Error/index.js";
import { createUTC } from "../utils.js";
import { getAppointmentId } from "../API";
import React from "react";
import ReactLoading from "react-loading";
import { Checkbox } from "@mantine/core";
import ClinicianSelect from "./ClinicianSelect";
import { v4 as uuidv4 } from "uuid";
import { createNotification } from "../Notifications";

function validateMobile(input, handler, isRequired = true) {
  if (!isRequired && !input) {
    handler("");

    return true;
  } else if (isRequired && !input) {
    handler("Phone number length is invalid");

    return false;
  }
  if (!isPossiblePhoneNumber(input)) {
    handler("Phone number length is invalid");
    return false;
  } else {
    handler("");
    return true;
  }
}

export const AppointmentForm = () => {
  let navigate = useNavigate();
  const [patientMobile, setPatientMobile] = useState();
  const [patientMobileError, setPatientMobileError] = useState();
  const [clinicianMobile, setClinicianMobile] = useState();
  const [clinicianMobileError, setClinicianMobileError] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [clinicianName, setClinicianName] = useState(null);
  const [clinicianEmail, setClinicianEmail] = useState(null);
  const [clinicianIsValid, setClinicianIsValid] = useState(null);
  const [doEmailClinician, setDoEmailClinician] = useState(false);
  const [doSMSClinician, setDoSMSClinician] = useState(false);

  return (
    <Formik
      initialValues={{
        patientFirst: "",
        patientLast: "",
        patientEmail: "",
        clinicianFirst: "",
        clinicianLast: "",
        clinicianEmail: "",
        appDate: "",
        appTime: "",
      }}
      validationSchema={Yup.object({
        patientFirst: Yup.string()
          .max(40, "Must be 40 characters or less")
          .required("Patient first name is required"),
        patientLast: Yup.string()
          .max(40, "Must be 40 characters or less")
          .required("Patient last name is required"),
        patientEmail: Yup.string().email("Invalid email address"),
        appDate: Yup.string().required("Appointment Date is required"),
        appTime: Yup.string().required("Appointment Time is required"),
      })}
      onSubmit={async (values) => {
        if (isSubmitting) {
          return;
        }
        const patientMobileValid = validateMobile(
          patientMobile,
          setPatientMobileError,
          false
        );
        const clinicianMobileValid = validateMobile(
          clinicianMobile,
          setClinicianMobileError,
          false
        );

        if (
          doSMSClinician &&
          (clinicianMobileValid === false || !clinicianMobile)
        ) {
          createNotification(
            "Text Clinician is checked but the number is invalid"
          );
          return;
        }

        if (clinicianIsValid === false) {
          createNotification("Clinician Must Be Specified");
          return;
        }

        const [clinicianFirst, clinicianLast] = clinicianName.split(" ");
        const roomName = uuidv4();
        setSubmitting(true);
        try {
          var meetingId = await getAppointmentId({
            roomName: roomName,
            providerLast: clinicianLast,
            patientLast: values.patientLast,
            meetingDate: createUTC(values.appDate, values.appTime),
            meetingTime: createUTC(values.appDate, values.appTime),
          });
        } catch (error) {
          console.error(error);
          createNotification(
            "Server could not be reached. Please try again later"
          );
          return;
        } finally {
          setSubmitting(false);
        }

        const formVals = {
          ...values,
          patientMobile,
          clinicianMobile,
          meetingId,
          clinicianFirst,
          clinicianLast,
          clinicianEmail,
          doEmailClinician,
          doSMSClinician,
          roomName,
        };

        navigate("/message", { state: formVals });

        setSubmitting(false);
      }}
    >
      <Form
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "5%",
        }}
      >
        <div class="formContainer">
          <div className="row">
            <FormInput
              label="*Patient First Name"
              name="patientFirst"
              type="text"
            />
            <FormInput
              label="*Patient Last Name"
              name="patientLast"
              type="text"
            />
          </div>
          <div className="row">
            <FormInput label="Patient Email" name="patientEmail" type="email" />
            <div class="field">
              <label htmlFor="patientMobile" class="label">
                Patient Mobile Number
              </label>
              <PhoneInput
                name="patientMobile"
                country="US"
                class="inputBox"
                value={patientMobile}
                onChange={setPatientMobile}
                onBlur={() => {
                  validateMobile(patientMobile, setPatientMobileError, false);
                }}
              />
              <Error msg={patientMobileError} />
            </div>
          </div>
          <div class="row">
            <FormInput label="*Appointment Date" name="appDate" type="date" />
            <FormInput label="*Appointment Time" name="appTime" type="time" />
          </div>
          <div class="row">
            <ClinicianSelect
              setName={setClinicianName}
              setEmail={setClinicianEmail}
              isValid={clinicianIsValid}
              setIsValid={setClinicianIsValid}
            />
            <div class="field">
              <label htmlFor="clinicianMobile" class="label">
                Clinician Mobile Number
              </label>
              <PhoneInput
                name="clinicianMobile"
                country="US"
                class="inputBox"
                value={clinicianMobile}
                onChange={setClinicianMobile}
                onBlur={() => {
                  validateMobile(
                    clinicianMobile,
                    setClinicianMobileError,
                    false
                  );
                }}
              />
              <Error msg={clinicianMobileError} />
            </div>
          </div>
          <div class="row">
            <Checkbox
              styles={{
                input: {
                  label: { color: "#2e64ae" },
                  color: "#2e64ae",
                  "&:checked": { backgroundColor: "#2e64ae" },
                },
              }}
              checked={doSMSClinician}
              onChange={() => setDoSMSClinician(!doSMSClinician)}
              label="Text Clinician Invitation"
            />
            <div style={{ width: "310px" }}>
              <Checkbox
                styles={{
                  input: {
                    label: { color: "#2e64ae" },
                    color: "#2e64ae",
                    "&:checked": { backgroundColor: "#2e64ae" },
                  },
                }}
                checked={doEmailClinician}
                onChange={() => setDoEmailClinician(!doEmailClinician)}
                label="Email Clinician Invitation"
              />
            </div>
          </div>
          <div class="appBtnRow">
            <button class="submitBtn" type="submit">
              {isSubmitting ? (
                <ReactLoading
                  type={"spinningBubbles"}
                  color={"white"}
                  height={"20px"}
                  width={"20px"}
                />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
