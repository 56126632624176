import React from "react";
import ReactLoading from "react-loading";

const Spinner = () => (
  <ReactLoading
    type={"spinningBubbles"}
    color={"#638a2f"}
    height={"5%"}
    width={"5%"}
  />
);

export default Spinner;
