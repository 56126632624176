import { Group, Button, Center, Modal, Text } from "@mantine/core";
import { useState } from "react";

const ConfirmationModal = ({
  opened,
  setOpened,
  title,
  description,
  action,
  actionArgs,
}) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <Modal opened={opened} onClose={() => setOpened(false)}>
      <Group>
        <Text sx={{ fontWeight: "bold" }} size="md">
          Are you sure you want to delete this appointment?
        </Text>
        <Text size="sm" color="#5B5B5B">
          If you delete the appointment, it will be removed from all dashboards
        </Text>
        <Center sx={{ width: "100%" }}>
          <Button
            color="white"
            variant="outline"
            sx={{
              marginRight: "10px",
              backgroundColor: "white",
              color: "#638a2f",
              border: "3px solid #638a2f",
            }}
            onClick={() => setOpened(false)}
          >
            Cancel
          </Button>
          <Button
            color="green"
            sx={{ marginLeft: "10px", backgroundColor: "#638a2f" }}
            loading={isLoading}
            onClick={async () => {
              setLoading(true);
              const [meetingId] = actionArgs;
              await action(...actionArgs);
              setLoading(false);
              setOpened(false);
            }}
          >
            Delete
          </Button>
        </Center>
      </Group>
    </Modal>
  );
};

export default ConfirmationModal;
