import FormSelect from "../FormSelect";
import { useState, useEffect } from "react";
import departments from "../departments";

const DepartmentSelect = ({ value, changeHandler, isValid, setIsValid }) => {
  //Remove "All" from the list of departments
  const [departmentOptions, setDepartmentOptions] = useState([]);
  useEffect(() => {
    setDepartmentOptions(departments.slice(1));
  }, []);

  return (
    <FormSelect
      label="Department"
      options={departmentOptions}
      value={value}
      changeHandler={changeHandler}
      isValid={isValid}
      setIsValid={setIsValid}
      name={"department"}
    />
  );
};
export default DepartmentSelect;
