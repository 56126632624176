import { FiAlertTriangle } from "react-icons/fi";
import { MdCheckCircleOutline, MdQueryBuilder } from "react-icons/md";
import { IconContext } from "react-icons";
export function getStatus(camera, mic, connectivity, participation) {
  let noun;
  let color;
  let icon;

  if (participation === "JOINED" || participation === "CONT") {
    noun = "Ready";
    color = "#2E64AE";
    icon = (
      <IconContext.Provider value={{ size: 20 }}>
        <MdCheckCircleOutline />
      </IconContext.Provider>
    );
  } else {
    noun = "Absent";
    color = "#F57E20";
    icon = (
      <IconContext.Provider value={{ size: 20 }}>
        <MdQueryBuilder />
      </IconContext.Provider>
    );
  }
  if (
    [0, 1].includes(mic) ||
    [0, 1].includes(camera) ||
    connectivity === "POOR"
  ) {
    noun = "Issue";
    color = "#C70808";
    icon = (
      <IconContext.Provider value={{ size: 18 }}>
        <FiAlertTriangle />
      </IconContext.Provider>
    );
  }
  return { noun, color, icon };
}

export function formatDate(datestamp) {
  const dateString = new Date(datestamp.replace(/-/g, "/")).toString();

  const dateArr = dateString.split(" ").slice(1, 4);
  return `${dateArr[0]} ${dateArr[1]}, ${dateArr[2]}`;
}

export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function capitalizeFirst(name) {
  return name[0].toUpperCase() + name.slice(1);
}
