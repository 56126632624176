import React, { createContext, useReducer } from "react";

const initialState = {
  auth: null,
  targetPage: "/dashboard",
  userModalOpen: false,
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SIGNED_IN":
        return { ...state, auth: action.data };
      case "SIGNED_OUT":
        return { ...state, auth: null };
      case "CAPTURE_TARGET":
        return { ...state, targetPage: action.data };
      case "OPEN_USER_MODAL":
        return { ...state, userModalOpen: true };
      case "CLOSE_USER_MODAL":
        return { ...state, userModalOpen: false };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
