import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { createUserDoc } from "./db";
import { v4 as uuidv4 } from "uuid";

const createSuccess = (obj) => ({ error: false, ...obj });
const createError = (errMsg, e = null) => ({ error: true, errMsg, e });

export const signIn = async (email, password) => {
  const auth = getAuth();
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    if (user != null) {
      return createSuccess({ auth: user.auth });
    }
  } catch (e) {
    throw e;
  }
};

export const createUser = async (
  email,
  first_name,
  last_name,
  department,
  role
) => {
  const password = uuidv4();
  const newUserAuth = getAuth();
  try {
    const userCredential = await createUserWithEmailAndPassword(
      newUserAuth,
      email,
      password
    );
    const user = userCredential.user;
    const userDetails = { email, first_name, last_name, department, role };
    const docRef = await createUserDoc(userDetails, user.uid);
    return createSuccess({ auth: user.auth });
  } catch (e) {
    throw e;
  }
};

export const resetPassword = async (email, auth) => {
  try {
    sendPasswordResetEmail(auth, email);
  } catch (e) {
    throw e;
  }
};
