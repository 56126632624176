import { Select } from "@mantine/core";
import { BiChevronDown } from "react-icons/bi";
import Error from "../Error";
import "./styles.css";

const FormSelect = ({
  label,
  options,
  value,
  changeHandler,
  isValid,
  setIsValid,
  overrideStyles = {},
}) => {
  const checkValidity = () => {
    if (value?.length > 0) setIsValid(true);
    else setIsValid(false);
  };

  return (
    <div>
      <div style={{ color: "#2e64ae", fontWeight: "bold", fontSize: "15px" }}>
        {label}
      </div>
      <Select
        styles={{
          input: {
            width: "300px",
            height: "50px",
            border: "2px solid #2e64ae",
            borderRadius: "4px",
            fontSize: "15px",
            paddingTop: "10px",
            paddingLeft: "10px",
            ...overrideStyles,
          },
        }}
        variant="headless"
        rightSection={<BiChevronDown size={14} />}
        rightSectionWidth={30}
        value={value}
        onChange={changeHandler}
        onBlur={checkValidity}
        data={options}
        searchable
      />
      {/* <div className="error"></div> */}
      {isValid === false ? (
        <Error msg={"Field is required"} />
      ) : (
        <div className="error"></div>
      )}
    </div>
  );
};

export default FormSelect;
