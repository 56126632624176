import { useContext } from "react";
import { store } from "../store";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const { state, dispatch } = useContext(store);
  const navigate = useNavigate();
  const isSignedIn = state.auth;
  const signOut = () => {
    dispatch({ type: "SIGNED_OUT", data: null });
    navigate("/");
  };
  const signIn = () => {
    navigate("/login");
  };
  return { isSignedIn, signOut, signIn };
};

export default useAuth;
