import React from "react";
import "./styles.css";
import { Formik, Form, useField } from "formik";
const LongInput = ({ CSSClass = "inputBox", label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="field">
      <label htmlFor={props.name} className="label">
        {label}
      </label>
      <textarea className={CSSClass} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
export default LongInput;
