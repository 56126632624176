import { Menu, ActionIcon } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import MenuIcon from "../assets/menu-icon.svg";
import { useAuth } from "../hooks";
import { useContext } from "react";
import { store } from "../store";
import { useUserInfo } from "../hooks";

const HamburgerMenu = () => {
  const { state, dispatch } = useContext(store);
  let navigate = useNavigate();
  const { isSignedIn, signOut, signIn } = useAuth();
  const userInfo = useUserInfo();
  const userIsAdmin = userInfo?.role === "super_admin" ? true : false;
  return (
    <div>
      <Menu
        control={
          <ActionIcon>
            <img src={MenuIcon} style={{ height: "4vh" }}></img>
          </ActionIcon>
        }
      >
        <Menu.Item
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            navigate("/appointment");
          }}
        >
          Create Appointment
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            navigate("/notify");
          }}
        >
          Contact Patient
        </Menu.Item>
        {userIsAdmin && (
          <Menu.Item
            onClick={() => {
              dispatch({ type: "OPEN_USER_MODAL", data: null });
            }}
          >
            Create User
          </Menu.Item>
        )}
        <Menu.Item onClick={isSignedIn ? signOut : signIn}>
          {isSignedIn ? "Sign Out" : "Sign In"}
        </Menu.Item>
        <Menu.Item component="a" href="https://afya.chat">
          Create eCARE Message
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default HamburgerMenu;
