import "./styles.css";
import { PageHeader } from "./PageHeader";
import Logo from "./Logo";
import UserButton from "./UserButton";
import HamburgerMenu from "./HamburgerMenu";
import { useMediaQuery } from "@mantine/hooks";
import { useContext } from "react";
import { store } from "../store";
import { useUserInfo } from "../hooks";

const NavBar = () => {
  const { dispatch } = useContext(store);
  const isSmallScreen = useMediaQuery("(max-width: 1470px)");
  const userInfo = useUserInfo();
  const userIsAdmin = userInfo?.role === "super_admin" ? true : false;
  return (
    <nav className="nav">
      <Logo />
      {isSmallScreen ? (
        <HamburgerMenu />
      ) : (
        <>
          <div className="pages">
            <PageHeader route="/dashboard" title="Dashboard" />
            <PageHeader route="/appointment" title="Create Appointment" />
            <PageHeader route="/notify" title="Contact Patient" />
            {userIsAdmin && (
              <PageHeader
                title="Create User"
                isInternalLink={false}
                action={() => {
                  dispatch({ type: "OPEN_USER_MODAL", data: null });
                }}
              />
            )}
            <PageHeader
              title="Create eCARE Message"
              isInternalLink={false}
              action={() => {
                window.open("https://afya.chat");
              }}
            />
          </div>
          <UserButton />
        </>
      )}
    </nav>
  );
};

export default NavBar;
