import { initializeApp } from "firebase/app";
import {
  setDoc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  getFirestore,
  doc,
  onSnapshot,
  writeBatch,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { env } from "./whitelabel.js";
import { createTimeStamp } from "./utils";
import { firebaseConfig } from "./firebase_config.js";
import { createNotification } from "./Notifications";

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const appointments = collection(db, "environments", env, "appointments");

const usersRef = collection(db, "environments", env, "users");

export const appsRef = collection(db, "environments", env, "appointments");

export const getUserInfo = async (uid) => {
  const userRef = doc(db, "environments", env, "users", uid);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return "";
  }
};

const getClinicianDepartment = async (email) => {
  try {
    const userRef = collection(db, "environments", env, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    const userDocs = querySnapshot.docs.map((doc) => {
      return doc.data().department;
    });
    return userDocs[0];
  } catch (e) {
    console.error("Error in getClinicianDepartment", e);
  }
};

export const createAppointment = async (details, roomId) => {
  try {
    const department = await getClinicianDepartment(details.clinicianEmail);
    const docRef = setDoc(
      doc(db, "environments", env, "appointments", roomId),
      { ...details, department, isHidden: false }
    );
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getUserList = async (department = "All", asObj = false) => {
  const userNames = [];
  try {
    let q;
    if (department !== "All") {
      q = query(usersRef, where("department", "==", department));
    } else {
      q = usersRef;
    }
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      const { first_name, last_name, email } = doc.data();
      const label = first_name + " " + last_name;
      if (asObj) {
        const value = JSON.stringify({ name: label, email: email });
        userNames.push({ label, value });
      } else userNames.push({ label, value: email });
    });
    return userNames;
  } catch (e) {
    console.error("error in getting a list of users", e);
    alert("Could not find users");
  }
};

export const updateAppointments = async () => {
  try {
    const q = query(
      appointments,
      where("associator", "==", "trevorkanigel@yahoo.com")
    );
    const snapshot = await getDocs(appointments);
    const docs = [];
    snapshot.forEach((doc) => {
      docs.push({ id: doc.id, data: doc.data() });
    });
    const batch = writeBatch(db);
    for (let record of docs) {
      const docRef = doc(db, "environments", env, "appointments", record.id);

      if (!("datetime" in record.data)) {
        const datetime = createTimeStamp(
          record.data.appDate,
          record.data.appTime
        );
        batch.update(docRef, { datetime: datetime });
      }
    }
    await batch.commit();
  } catch (e) {
    console.error("Error occured in updateAppointments method:", e);
  }
};

export const createUserDoc = async (details, uid) => {
  try {
    const docRef = await setDoc(
      doc(db, "environments", env, "users", uid),
      details
    );
    return docRef;
  } catch (e) {
    console.error("Error in creating user document ", e);
  }
};

function isEqual(fresh, old) {
  const patientFresh = fresh.patientStatus;
  const patientOld = old.patientStatus;
  return (
    patientFresh.camera === patientOld.camera &&
    patientFresh.connectivity === patientOld.connectivity &&
    patientFresh.mic === patientOld.mic &&
    patientFresh.participation === patientOld.participation
  );
}

export const streamAppointments = (
  startDate,
  endDate,
  provider,
  department,
  snapshot,
  error,
  loadingHandler
) => {
  loadingHandler(true);

  let lowerLimit = startDate.toDate();
  let upperLimit = endDate.toDate();
  lowerLimit.setHours(0, 0, 0, 0);
  upperLimit.setHours(0, 0, 0, 0);

  const appRef = collection(db, "environments", env, "appointments");
  const filters = [];

  if (provider !== "All") filters.push(where("associator", "==", provider));
  if (department !== "All") filters.push(where("department", "==", department));
  let q = query(
    appRef,
    ...filters,
    where("datetime", "<=", upperLimit),
    where("datetime", ">=", lowerLimit),
    orderBy("datetime")
  );
  return onSnapshot(q, snapshot, error);
};

export const deleteAppointment = async (appointmentId) => {
  try {
    const q = query(appointments, where("id", "==", appointmentId));
    const deletedDoc = doc(
      db,
      "environments",
      env,
      "appointments",
      appointmentId
    );
    await updateDoc(deletedDoc, { isHidden: true });
  } catch (error) {
    console.error("Doc could not be deleted", error);
  }
};
