import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Splash from "./Splash";
import { AppointmentForm } from "./ApppointmentForm";
import MessageConfirmation from "./MessageConfirmation";
import Notify from "./Notify";
import Completion from "./Completion";
import Room from "./Room";
import RoomContainer from "./RoomContainer";
import NoMatch from "./NoMatch";
import { StateProvider } from "./store.js";
import RequireAuth from "./RequireAuth";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <MantineProvider theme={{ colors: { "ocean-blue:": ["#2E64AE"] } }}>
        <NotificationsProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<Splash />} />
                <Route
                  path="appointment"
                  element={
                    <RequireAuth>
                      <AppointmentForm />
                    </RequireAuth>
                  }
                />
                <Route path="message" element={<MessageConfirmation />} />
                <Route
                  path="notify"
                  element={
                    <RequireAuth>
                      <Notify />
                    </RequireAuth>
                  }
                />
                <Route path="completion" element={<Completion />} />
                <Route path="*" element={<NoMatch />} />
                <Route
                  path="/dashboard"
                  element={
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route path="/r" element={<RoomContainer />}>
                <Route path=":roomId" element={<Room />} />
                <Route index element={<Splash />} />
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
