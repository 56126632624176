import "./styles.css";
import vcImage from "../assets/virtual-care-graphic.png";
import LongInput from "../LongInput/index.js";
import { sendSMS } from "../API/index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import PhoneInput from "react-phone-number-input/input";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import Error from "../Error/index.js";
import { useState } from "react";
import { createNotification } from "../Notifications";

function validateMobile(input, handler) {
  if (!input) {
    handler("Phone number length is invalid");

    return false;
  }
  if (!isPossiblePhoneNumber(input)) {
    handler("Phone number length is invalid");
    return false;
  } else {
    handler("");
    return true;
  }
}

const Notify = (props) => {
  let [patientMobile, setPatientMobile] = useState();
  const [patientMobileError, setPatientMobileError] = useState();
  let navigate = useNavigate();
  return (
    <div
      style={{
        height: "100%",
        minHeight: "90vh",
        width: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "10%",
      }}
    >
      <div class="fstCol">
        <Formik
          initialValues={{
            patientMsg: "",
          }}
          validationSchema={Yup.object({
            patientMsg: Yup.string().required("Message is Required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (!validateMobile(patientMobile, setPatientMobile)) return;
            setSubmitting(false);
            try {
              if (patientMobile) sendSMS(patientMobile, values.patientMsg);
            } catch (error) {
              console.error("Error while sendingSMS", error);
              createNotification("Message could not be sent.");
              return;
            }
            patientMobile = "";
            resetForm();
            const vals = {
              patientFirst: "",
              patientLast: "",
              clinicianFirst: "",
              clinicianLast: "",
            };
            navigate("/completion", { state: vals });
          }}
        >
          <Form>
            <div class="wrapCol">
              <div class="field">
                <label htmlFor="patientMobile" class="label">
                  *Patient Mobile Number
                </label>
                <PhoneInput
                  name="patientMobile"
                  country="US"
                  class="longInputBox"
                  value={patientMobile}
                  onChange={setPatientMobile}
                  placeholder="(555) 555-5555"
                  onBlur={() => {
                    validateMobile(patientMobile, setPatientMobileError);
                  }}
                />
                <Error msg={patientMobileError} />
              </div>
              <LongInput
                label="*Message"
                name="patientMsg"
                type="textarea"
                class="bigInput"
              />
              <div class="bottomRow">
                <button class="sendBtn" type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>

      <div class="sndCol">
        <img src={vcImage} alt="virtual care graphic" class="imgNotify" />
      </div>
    </div>
  );
};

export default Notify;
