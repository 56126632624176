import Spinner from "./Spinner.js";
import Appointment from "./Appointment.js";
import { memo } from "react";

const AppointmentList = ({ loadingStatus, appointments, deletionHandler }) => {
  return loadingStatus ? (
    <Spinner />
  ) : (
    <div>
      <div
        style={{
          fontSize: "16px",
          textAlign: "center",
          marginBottom: "20px",
          color: "#797878",
        }}
      >
        {appointments.length} Matching Appointments
      </div>
      {appointments.map((doc) => {
        return (
          <Appointment
            key={doc.meetingId}
            room={doc}
            handleDelete={deletionHandler}
          />
        );
      })}
    </div>
  );
};

export default memo(AppointmentList);
