import { useState, useEffect, useContext } from "react";
import { getUserInfo } from "../db";
import { store } from "../store";
import useAuth from "./useAuth";

const useUserInfo = () => {
  const { isSignedIn } = useAuth();
  const { state } = useContext(store);
  const [userInfo, setUserInfo] = useState({});
  const uid = isSignedIn ? state.auth.currentUser.uid : null;
  useEffect(() => {
    const addUserInfo = async () => {
      try {
        const userData = await getUserInfo(uid);
        setUserInfo(userData);
      } catch (error) {
        console.error(error);
      }
    };
    if (isSignedIn) addUserInfo();
  }, [uid]);
  return isSignedIn ? userInfo : null;
};

export default useUserInfo;
