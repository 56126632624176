// export const firebaseConfig = {
//   apiKey: "AIzaSyBRxZh4eOsT_SIsmYS5knqI7cP338m43yk",
//   authDomain: "appointment-portal-staging.firebaseapp.com",
//   projectId: "appointment-portal-staging",
//   storageBucket: "appointment-portal-staging.appspot.com",
//   messagingSenderId: "256750409701",
//   appId: "1:256750409701:web:32865727408bdf9dbb010c",
//   measurementId: "G-1SBFJEG20K",
// };

// Production database

export const firebaseConfig = {
  apiKey: "AIzaSyDdceSPThtcW0jWCQmgTU90_AAP8giXuPs",
  authDomain: "website-hosting-309708.firebaseapp.com",
  projectId: "website-hosting-309708",
  storageBucket: "website-hosting-309708.appspot.com",
  messagingSenderId: "461144805134",
  appId: "1:461144805134:web:f14a869d63052b854ad15f",
  measurementId: "${config.measurementId}",
};
