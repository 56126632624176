export const convertTime = (time) => {
  let timeUnits = time.split(":");
  let hours = parseInt(timeUnits[0]);
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const res_time = hours.toString() + ":" + timeUnits[1] + " " + period;
  return res_time;
};

export const removeDash = (string) => {
  return string.replace(/-/g, "");
};

export const addDash = (id) => {
  return `${id.slice(0, 8)}-${id.slice(8, 12)}-${id.slice(12, 16)}-${id.slice(
    16,
    20
  )}-${id.slice(20, 32)}`;
};

export const convertDate = (date) => {
  let dateUnits = date.split("-");
  return dateUnits[1] + "/" + dateUnits[2] + "/" + dateUnits[0];
};

export const getWeekDate = (datestamp, i_time) => {
  const date = new Date(`${datestamp} ${i_time}`.replace("/-/g", "/"));
  const datestring = date.toString();
  const weekdays = new Array(
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  );
  const day = date.getDay();
  return weekdays[day];
};

export const createUTC = (date, time) => {
  const datetime = `${date} ${time}`;
  return new Date(datetime).toISOString();
};

export const createURL = (base_url, id) => {
  return `${base_url}${removeDash(id)}`;
};

export const createSuccess = (obj) => ({ error: false, ...obj });
export const createError = (errMsg, e = null) => ({ error: true, errMsg, e });

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export const createTimeStamp = (appDate, appTime) => {
  return new Date(`${appDate} ${appTime}`.replace("/-/g", "/"));
};

export const capitalizeFirst = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const readAuthError = (error) => {
  return error?.code
    ?.split("/")[1]
    ?.split("-")
    ?.map(capitalizeFirst)
    ?.join(" ");
};
